<template>
  <div class="template-page">
    <div v-loading.fullscreen.lock="loading" class="content" flex="main:justify">
      <div v-if="!pdfDownload" class="flex_1 m-r-10 b-r p-l-10 p-t-10 p-r-10 p-b-20 left-page">
        <avue-crud ref="lossDecorationList" class="m-b-20" :data="formData.lossDecorationList"
          :option="decorationLossObjOption"
          @row-update="(form, index, done) => rowUpdate(form, index, done, 'lossDecorationList')"
          @row-del="(form, index) => rowDel(form, index, 'lossDecorationList')"
          @row-save="(form, done) => rowSave(form, done, 'lossDecorationList')"
          @row-click="(row, event, column) => handleRowClick(row, event, column, 'lossDecorationList')" />
        <avue-crud ref="lossDeviceList" class="m-b-20" :data="formData.lossDeviceList" :option="deviceLossObjOption"
          @row-update="(form, index, done) => rowUpdate(form, index, done, 'lossDeviceList')"
          @row-del="(form, index) => rowDel(form, index, 'lossDeviceList')"
          @row-save="(form, done) => rowSave(form, done, 'lossDeviceList')"
          @row-click="(row, event, column) => handleRowClick(row, event, column, 'lossDeviceList')">
        </avue-crud>
        <avue-form ref="form" v-model="formData" :option="formOption">
          <template slot="preparer" slot-scope="scope">
            <div flex="cross:center main:justify">
              <img v-if="scope.value" class="flex_1" :src="scope.value" alt="" height="32" />
              <el-link class="margin_left_10" type="primary" @click="signatureVisible = true">签名</el-link>
            </div>
          </template>
        </avue-form>
      </div>
      <LossTem v-if="!isMobile" class="flex_1 template" :formData="formData" :pdfDownload="pdfDownload" />
    </div>
    <DownloadBtn class="download-btn" :pdfDownload="pdfDownload" title="损失清单" :keyId="params.keyId" :formData="formData"
      :moduleName="currentModule.moduleName" @saveData="submitFormBackBefore" />
    <FooterBtn class="footer" :pdfDownload="pdfDownload" :submitDis="false" @submit="submitForm" @cancel="handleCancel" />
    <SignatureDialog v-model="signatureVisible" @submit="generate" />
  </div>
</template>

<script>
import DataFlow from '@/mixins/DataFlow'
import { getUser as GetUser } from '@/api/system/user'
import { pickerOptions } from '@/const/avue'
import FooterBtn from '@/components/FooterBtn'
import SignatureDialog from '@/components/SignatureDialog'
import DownloadBtn from '@/components/DownloadBtn'
import LossTem from '@/components/Template/loss.vue'
export default {
  mixins: [DataFlow],
  components: { LossTem, FooterBtn, SignatureDialog, DownloadBtn, },
  data() {
    return {
      printObj: {
        id: 'view_warper',
        popTitle: '', // 打印配置页上方的标题
        extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
        preview: false, // 是否启动预览模式，默认是false
        previewTitle: '预览的标题', // 打印预览的标题
        previewPrintBtnLabel: '预览结束，开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
        zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高
        standard: '',
        extarCss: '',
        beforeOpenCallback() {
          console.log('开始打印之前！');
        },
        previewOpenCallback() {
          // console.log('关闭了打印工具');
        }
      },
      isDownload: false,
      signatureVisible: false,
      currentKey: "",
      pdfDownload: false,
      isEdit: false,
      userInfo: {},
    };
  },
  computed: {
    decorationLossObjOption() {
      return {
        menuWidth: 100,
        editBtn: false,
        dialogType: "drawer",
        dialogWidth: this.isMobile ? "100vw" : "500",
        columnBtn: false,
        refreshBtn: false,
        addBtnText: "新增建构筑物及装修",
        border: true,
        labelWidth: "120",
        column: [
          {
            label: "建筑名称",
            prop: "name",
            rules: [
              {
                required: true,
                message: "请输入建筑名称",
                trigger: "blur"
              }
            ],
            span: 24
          },
          {
            label: "烧损面积m2",
            prop: "burningArea",
            type: "number",
            min: 0,
            rules: [
              {
                required: true,
                message: "请输入烧损面积m2",
                trigger: "blur"
              }
            ],
            span: 24,
            controlsPosition: "top",
            change: ({ value }, form) => {
              const tableForm = this.$refs.lossDecorationList.tableForm;
              tableForm.burningArea1 = value;
            }
          },

          {
            label: "建造时价格(元)",
            prop: "buildingValue",
            type: "number",
            min: 0,
            rules: [
              {
                required: true,
                message: "请输入建造时价格",
                trigger: "blur"
              }
            ],
            span: 24,
            controlsPosition: "top",
            change: ({ value }) => {
              const tableForm = this.$refs.lossDecorationList.tableForm;
              tableForm.rebuildValue = value + 5;
            }
          },
          {
            label: "使用时间(年)",
            // labelTip: '使用时间（年）',
            prop: "buildedYear",
            type: "number",
            min: 0,
            rules: [
              {
                required: true,
                message: "请输入使用时间",
                trigger: "blur"
              }
            ],
            span: 24,
            controlsPosition: "top"
          },
          {
            label: "价值判定",
            prop: "userable",
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择价值判定",
                trigger: "blur"
              }
            ],
            type: "radio",
            hide: true,
            value: true,
            dicData: [
              { label: "还有使用价值", value: true },
              { label: "无使用价值", value: false }
            ]
          },
          {
            label: "建筑类别",
            labelTip: "房屋类别",
            prop: "category",
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择建筑类别",
                trigger: "change"
              }
            ],
            type: "cascader",
            dicUrl: `/archives/depreciat/listTree?isDic=true`,
            emitPath: false,
            props: {
              label: "name",
              value: "name"
            },
            width: "600",
            hide: true,
            dicHeaders: this.headers,
            filterable: true,
            change: ({ value }) => {
              const tableForm = this.$refs.lossDecorationList.tableForm;
              const arr = value.split("-");
              tableForm.rebuildYear = parseInt(arr[2]);
            },
          },

          {
            label: "折旧年限",
            prop: "rebuildYear",
            labelTip: "折旧年限（年）",
            type: "number",
            min: 0,
            rules: [
              {
                required: true,
                message: "折旧年限",
                trigger: "blur"
              }
            ],
            span: 24,
            controlsPosition: "top",
            display: !this.isMobile
          },

          {
            label: "烧损程度",
            prop: "burnLevel",
            labelTip: `
              <p>轻微烧损：1.仅外观受损，使用功能和精确度未受影响，通过一般的维护、保养，即可修复。</p>
              <p style="text-indent: 60px;">2.少量零部件、附属件受损，使用功能和精确度基本未受影响，通过小修，进行简单的修理或更换，即可修复。</p>
              <p> 一般烧损：部分零部件、附属件损坏，导致部分使用功能和精确度降低或丧失，需通过项修，部分拆卸分解，修理或更换烧损件，才能修复。</p>
              <p>严重烧损：1.大部分零部件、附属件或关键零部件损坏，导致大部分使用功能和精确度降低或丧失，必须通过大修，全部拆卸分解，修理或更换烧损件，才能修复。</p>
              <p style="text-indent: 60px;">2.部分使用功能或精确度虽不能修复到火灾前的使用状态，但能满足使用要求，尚可使用。</p>
              <p>全部烧损：1.烧损后无法修复使用。</p>
              <p style="text-indent: 60px;">2.大部分零部件、附属件或关键零部件损坏、失去了原有的全部使用价值。</p>
              <p style="text-indent: 60px;">3.修复费达到国家有关部门规定的报废标准。</p>
              <p style="text-indent: 60px;">4.经过鉴定，大修后虽能修复，但不如更新经济的；或大修后不能满足使用要求的。</p>

            `,
            span: 24,
            type: "select",
            dicUrl: `/system/dict/data/type/burn_type?isDic=true`,
            props: {
              label: "dictLabel",
              value: "dictValue"
            },
            hide: true,
            dicHeaders: this.headers,
            change: ({ value }) => {
              const tableForm = this.$refs.lossDecorationList.tableForm;
              tableForm.burningRate = parseInt(value);
            }
          },

          {
            label: "烧损率",
            prop: "burningRate",
            labelTip: "烧损率（%）",
            type: "number",
            min: 0,
            rules: [
              {
                required: true,
                message: "请输入烧损率",
                trigger: "blur"
              }
            ],
            span: 24,
            controlsPosition: "top",
            display: !this.isMobile
          },
          {
            label: "烧损面积",
            labelTip: "烧损面积（m2）",
            prop: "burningArea1",
            rules: [
              {
                required: true,
                message: "请输入烧损面积",
                trigger: "blur"
              }
            ],
            type: "number",
            min: 0,
            span: 24,
            controlsPosition: "top",
            display: !this.isMobile
          },
          {
            label: "重置价值",
            labelTip: "重置价值或修复费",
            prop: "rebuildValue",
            type: "number",
            min: 0,
            span: 24,
            controlsPosition: "top",
            display: !this.isMobile
          }
        ]
      };
    },
    deviceLossObjOption() {
      return {
        menuWidth: 120,
        editBtn: false,
        dialogType: "drawer",
        dialogWidth: this.isMobile ? "100vw" : "500",
        columnBtn: false,
        refreshBtn: false,
        addBtnText: "新增设备及其他财产",
        labelWidth: "110",
        border: true,
        column: [
          {
            label: "名称",
            prop: "name",
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }
            ],
            span: 24
          },
          {
            label: "数量",
            prop: "count",
            type: "number",
            min: 0,
            rules: [
              {
                required: true,
                message: "请输入数量",
                trigger: "blur"
              }
            ],
            span: 24,
            controlsPosition: "top",
            change: ({ value }) => {
              const tableForm = this.$refs.lossDeviceList.tableForm;
              tableForm.unitCount = value;
            },
            min: 0,
            step: 1,
            stepStrictly: true,
            precision: 0
          },
          {
            label: "单价",
            labelTip: "购进时单价（元）",
            prop: "buyingValue",
            type: "number",
            min: 0,
            rules: [
              {
                required: true,
                message: "请输入单价",
                trigger: "blur"
              }
            ],
            span: 24,
            controlsPosition: "top",
            change: ({ value }) => {
              const tableForm = this.$refs.lossDeviceList.tableForm;
              tableForm.unitPrice = value;
            }
          },
          {
            label: "使用时间",
            labelTip: "已使用时间（年）",
            prop: "usedValue",
            type: "number",
            min: 0,
            rules: [
              {
                required: true,
                message: "请输入使用时间",
                trigger: "blur"
              }
            ],
            span: 24,
            controlsPosition: "top"
          },
          {
            label: "价值判定",
            prop: "userable",
            span: 24,
            type: "radio",
            hide: true,
            value: true,
            dicData: [
              { label: "还有使用价值", value: true },
              { label: "无使用价值", value: false }
            ]
          },
          {
            label: "设备类别",
            prop: "category",
            rules: [
              {
                required: true,
                message: "请输入设备类别",
                trigger: "blur"
              }
            ],
            span: 24,
            type: "cascader",
            dicUrl: `/archives/depreciat/listTree?isDic=true`,
            emitPath: false,
            props: {
              label: "name",
              value: "name"
            },
            width: "600",
            hide: true,
            dicHeaders: this.headers,
            filterable: true,
            change: ({ value }) => {
              const tableForm = this.$refs.lossDeviceList.tableForm;
              const arr = value.split("-");
              tableForm.reusedYear = parseInt(arr[2]);
            }
          },
          {
            label: "折旧年限",
            labelTip: "已使用时间（年）",
            prop: "reusedYear",
            type: "number",
            min: 0,
            rules: [
              {
                required: true,
                message: "请输入折旧年限",
                trigger: "blur"
              }
            ],
            span: 24,
            controlsPosition: "top"
          },
          {
            label: "烧损程度",
            prop: "burnLevel",
            rules: [
              {
                required: true,
                message: "请输入烧损程度",
                trigger: "blur"
              }
            ],
            span: 24,
            type: "select",
            dicUrl: `/system/dict/data/type/burn_type?isDic=true`,
            props: {
              label: "dictLabel",
              value: "dictValue"
            },
            hide: true,
            dicHeaders: this.headers,
            change: ({ value }) => {
              const tableForm = this.$refs.lossDeviceList.tableForm;
              tableForm.burningRate = parseInt(value);
            }
          },
          {
            label: "烧损率",
            labelTip: "烧损率（%）",
            prop: "burningRate",
            rules: [
              {
                required: true,
                message: "请输入烧损率",
                trigger: "blur"
              }
            ],
            type: "number",
            min: 0,
            span: 24,
            controlsPosition: "top"
          },
          {
            label: "单价",
            labelTip: "重置价值（单价）",
            prop: "unitPrice",
            rules: [
              {
                required: true,
                message: "请输入单价",
                trigger: "blur"
              }
            ],
            type: "number",
            min: 0,
            span: 24,
            controlsPosition: "top"
          },
          {
            label: "数量",
            labelTip: "重置价值（数量）",
            prop: "unitCount",
            rules: [
              {
                required: true,
                message: "请输入数量",
                trigger: "blur"
              }
            ],
            type: "number",
            min: 0,
            span: 24,
            controlsPosition: "top"
          }
        ]
      };
    },
    formOption() {
      return {
        submitBtn: false,

        column: [
          {
            label: "填表人",
            prop: "preparer"
          },
          {
            label: "填表时间",
            prop: "preparerTime",
            type: "datetime",
            format: 'yyyy年MM月dd日 HH时mm分',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            pickerOptions
          },
          {
            label: "联系人",
            prop: "contactor",
            value: ''
          },
          {
            label: "联系电话",
            prop: "contactorPhone"
          },
          {
            label: "统计单位",
            prop: "statisticsOrg",
            display: !this.isMobile,
            span: 24
          },
          {
            label: "统计人",
            prop: "statisticstor"
          },
          {
            label: "统计时间",
            prop: "statisticsTime",
            type: "date",
            format: 'yyyy年MM月dd日',
            valueFormat: 'yyyy-MM-dd',
            display: !this.isMobile,
            pickerOptions
          },
          {
            label: "审批人",
            prop: "approvaltor",
            display: !this.isMobile
          },
          {
            label: "审批时间",
            prop: "approvalTime",
            type: "date",
            format: 'yyyy年MM月dd日',
            valueFormat: 'yyyy-MM-dd',
            display: !this.isMobile,
            pickerOptions
          }
        ]
      };
    },
    isMobile() {
      return window.screen.width <= 540;
    },
    isPad() {
      return window.innerWidth <= 1200;
    }
  },
  methods: {
    async beforeHandleData() {
      this.userInfo = (await GetUser(this.$route.query.userId)).data
    },

    generate(base64) {
      this.formData.preparer = base64
    },
    handleCache() {
      this.handleCurrentRow();
      localStorage.setItem("currentRow", JSON.stringify(this.currentRow));
    },
    rowSave(form, done, key) {
      const formData = JSON.parse(JSON.stringify(form));
      this.formData[key].push(formData);
      this.$message.success("操作成功");
      done();
    },
    rowUpdate(form, index, done, key) {
      const formData = JSON.parse(JSON.stringify(form));
      this.formData[key].splice(index, 1, formData);
      this.$message.success("操作成功");
      done();
    },
    rowDel(form, index, key) {
      this.formData[key].splice(index, 1);
      this.$message.success("操作成功");
      // done()
    },
    handleRowClick(row, event, column, key) {
      this.$refs[key].rowEdit(row, row.$index);
    },
    initForm() {
      let initForm = {
        remark: "",
        preparer: "",
        preparerTime: this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
        contactor: '',
        contactorPhone: '',
        statisticsOrg: this.userInfo.dept?.deptName || '',
        statisticstor: this.userInfo.nickName,
        statisticsTime: this.parseTime(new Date().getTime(), "{y}-{m}-{d}"),
        approvaltor: this.userInfo.nickName,
        approvalTime: this.parseTime(new Date().getTime(), "{y}-{m}-{d}"),
        lossDeviceList: [],
        lossDecorationList: []
      };
      localStorage.removeItem("currentRow");
      return initForm
    },
    handleCurrentRow() {
      let { jsonStr } = this.currentRow;
      jsonStr = jsonStr && jsonStr != "" ? JSON.parse(jsonStr) : [];
      this.formData.createTime = new Date().getTime();
      if (this.currentIndex) {
        this.currentRow.handleType = 2;
        jsonStr.splice(this.currentIndex, 1, this.formData);
      } else {
        this.currentRow.handleType = 1;
        jsonStr.push(this.formData);
      }
      this.currentRow.jsonStr = JSON.stringify(jsonStr);
    },
  }
};
</script>
<style lang="scss" scoped></style>
